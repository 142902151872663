import {
  FormControl,
  FormLabel,
  Button,
  FormHelperText,
  VStack,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Link,
  AlertIcon,
  AlertTitle,
  Alert
} from '@chakra-ui/react';
import { Formik, Field, Form, FormikProps } from 'formik';
import { useNewTeacherContext } from '../../contexts/NewTeacherProvider';
import Terms from './Terms';
import { NewTeacher, createTeacher } from '../../utils/clients/EstudiAdminClient';
import { useState } from 'react';
import { sendAdditionalCourses } from '../../utils/clients/FormspreeClient';

interface FormValues {
  acceptTermsAndConditions: boolean;
}

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
};

const getExternalId = (fullName: string): string => {
  const words = fullName.split(' ');

  let externalId = words[0].toLocaleLowerCase();

  for (let i = 1; i < words.length; i++) {
    externalId += capitalize(words[i]);
  }

  return externalId.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const getName = (str: string): string => {
  const words = str.split(' ');
  const capitalizedWords = words.map(capitalize);

  return capitalizedWords.join(' ');
};

const getAvailableDays = (busyDays: string[]): string[] => {
  const allDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  const availableDays = new Set(allDays);

  busyDays.forEach((day) => availableDays.delete(day));

  return Array.from(availableDays);
};

const Confirmation = () => {
  const { teacher, updateTeacher } = useNewTeacherContext();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleBack = async () => {
    await updateTeacher({ step: teacher.step - 1 });
  };

  const handleSubmit = async (values: any, actions: any) => {
    setLoading(true);
    const teacherToSubmit: NewTeacher = {
      external_id: getExternalId(teacher.firstName + ' ' + teacher.lastName),
      email: teacher.email.toLocaleLowerCase(),
      phone_number: teacher.phoneNumber,
      first_name: getName(teacher.firstName),
      last_name: getName(teacher.lastName),
      profile_description: teacher.description,
      full_description: teacher.fullDescription,
      available_days_of_week: getAvailableDays(teacher.busyDays),
      price_per_hour: parseFloat('' + teacher.pricePerHour),
      dictated_courses: Array.from(teacher.dictatedCourses.values()).flat(),
      business_hours: { startTime: '08:00', endTime: '22:00' },
      image: teacher.profilePictureBase64,
      configuration: {
        in_person_classes: teacher.inPersonClasses,
        in_person_classes_message: teacher.inPersonClassesMessage,
        virtual_classes: teacher.virtualClasses,
        virtual_classes_message: teacher.virtualClassesMessage,
        weeks_in_advance_for_reserve: teacher.weeksInAdvance
      }
    };

    createTeacher(teacherToSubmit)
      .then((createdTeacher) => {
        if (teacher.additionalCoursesMessage.length > 0) {
          sendAdditionalCourses(teacher.email, teacher.additionalCoursesMessage);
        }
        updateTeacher({ step: teacher.step + 1, externalId: createdTeacher.external_id });
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => setLoading(false));

    actions.setSubmitting(false);
  };

  return (
    <Formik initialValues={{ acceptTermsAndConditions: false }} onSubmit={handleSubmit}>
      {(props: FormikProps<FormValues>) => {
        const { isSubmitting } = props;
        const { isOpen, onOpen, onClose } = useDisclosure();

        return (
          <Form>
            <VStack spacing="12px" margin="1rem">
              <Field name="acceptTermsAndConditions">
                {({}: { field: any }) => (
                  <FormControl isRequired>
                    <FormLabel>Términos y Condiciones</FormLabel>
                    {error && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertTitle>Oops! Ocurrio un error</AlertTitle>
                      </Alert>
                    )}
                    <FormHelperText textAlign="left">
                      Al continuar con el formulario presionando el botón "Enviar" estas Aceptando
                      nuestros{' '}
                      <Link color="blue.500" onClick={onOpen}>
                        Términos y Condiciones
                      </Link>
                      .
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Términos y Condiciones - Profesores</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Terms />
                  </ModalBody>
                </ModalContent>
              </Modal>
              <Stack display="flex" flexDirection="row" spacing={6}>
                <Button mt={4} variant="outline" colorScheme="blue" onClick={handleBack}>
                  Atras
                </Button>
                <Button
                  mt={4}
                  colorScheme="blue"
                  isLoading={isSubmitting || loading}
                  type="submit"
                  isDisabled={loading}>
                  Enviar
                </Button>
              </Stack>
              <Alert status="info">
                <AlertIcon />
                <AlertTitle>
                  El proceso puede demorar al enviar, espera unos instantes y cierres la pestaña de
                  tu navegador, espera la confirmación.
                </AlertTitle>
              </Alert>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Confirmation;

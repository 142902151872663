import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

export interface DictatedCourse {
  external_id: string;
  notes: string | undefined;
}

interface NewTeacherContextProps {
  teacher: {
    step: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    description: string;
    fullDescription: string;
    pricePerHour: number;
    busyDays: string[];
    inPersonClasses: boolean;
    inPersonClassesMessage: string;
    virtualClasses: boolean;
    virtualClassesMessage: string;
    weeksInAdvance: number;
    profilePictureBase64: string;
    dictatedCourses: Map<string, DictatedCourse[]>;
    additionalCoursesMessage: string;
    externalId: string;
  };
  updateTeacher: (teacher: Partial<NewTeacherContextProps['teacher']>) => void;
}

const defaultState = {
  teacher: {
    step: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    description: '',
    fullDescription: '',
    pricePerHour: 0,
    busyDays: [] as string[],
    inPersonClasses: false,
    inPersonClassesMessage: '',
    virtualClasses: true,
    virtualClassesMessage: '',
    weeksInAdvance: 4,
    profilePictureBase64: '',
    dictatedCourses: new Map<string, DictatedCourse[]>(),
    additionalCoursesMessage: '',
    externalId: ''
  },
  updateTeacher: () => {}
};

const serializeTeacher = (teacher: NewTeacherContextProps['teacher']): string => {
  const teacherObject = {
    ...teacher,
    dictatedCourses: Array.from(teacher.dictatedCourses)
  };

  return JSON.stringify(teacherObject);
};

const deserializeTeacher = (teacherString: string): NewTeacherContextProps['teacher'] => {
  const teacherObject = JSON.parse(teacherString);

  return { ...teacherObject, dictatedCourses: new Map(teacherObject.dictatedCourses) };
};

const NewTeacherContext = createContext<NewTeacherContextProps>(defaultState);

export const useNewTeacherContext = () => useContext(NewTeacherContext);

interface TeacherProviderProps {
  children: ReactNode;
}

export const NewTeacherProvider: React.FC<TeacherProviderProps> = ({ children }) => {
  const [teacher, setTeacher] = useState(defaultState.teacher);

  useEffect(() => {
    const storedTeacher = localStorage.getItem('teacher');

    if (storedTeacher) {
      setTeacher(deserializeTeacher(storedTeacher));
    }
  }, []);

  const updateTeacher = async (
    newUserData: Partial<NewTeacherContextProps['teacher']>
  ): Promise<void> => {
    await setTeacher((current) => {
      const mergedTeacher = { ...current, ...newUserData };

      localStorage.setItem('teacher', serializeTeacher(mergedTeacher));

      return mergedTeacher;
    });
  };

  return (
    <NewTeacherContext.Provider value={{ teacher, updateTeacher }}>
      {children}
    </NewTeacherContext.Provider>
  );
};

export default NewTeacherProvider;

import { DictatedCourse } from '../../contexts/NewTeacherProvider';

export interface Institution {
  external_id: string;
  name: string;
}

export interface Course {
  external_id: string;
  name: string;
  institution: Institution;
}

export interface BusinessHours {
  startTime: string;
  endTime: string;
}

export interface Configuration {
  in_person_classes: boolean;
  in_person_classes_message: string;
  virtual_classes: boolean;
  virtual_classes_message: string;
  weeks_in_advance_for_reserve: number;
}

export interface NewTeacher {
  external_id: string;
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  profile_description: string;
  full_description: string;
  available_days_of_week: string[];
  price_per_hour: number;
  dictated_courses: DictatedCourse[];
  business_hours: BusinessHours;
  image: string;
  configuration: Configuration;
}

export const fetchInstitutions = async () => {
  const response = await fetch(`${process.env.REACT_APP_ESTUDI_API_URL}/estudi/v1/institutions`);
  const data = await response.json();

  return data as Institution[];
};

export const fetchCourses = async () => {
  const response = await fetch(`${process.env.REACT_APP_ESTUDI_API_URL}/estudi/v1/courses`);
  const data = await response.json();

  return data as Course[];
};

export const createTeacher = (newTeacher: NewTeacher): Promise<NewTeacher> => {
  const myHeaders = new Headers();

  myHeaders.append('x-api-key', process.env.REACT_APP_ESTUDI_TEACHERS_ADMIN_API_KEY!);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(newTeacher);
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  } as RequestInit;

  return fetch(`${process.env.REACT_APP_ESTUDI_API_URL}/estudi/v1/teachers`, requestOptions).then(
    (response) => {
      if (response.status === 201) {
        return response.json();
      }

      return Promise.reject();
    }
  );
};
